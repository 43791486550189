@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

:root {
  --font-base: 'DM Sans', sans-serif;

  --primary-color: #edf2f8;
  --secondary-color: #313bac;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 11px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--gray-color) var(--primary-color);
}
body::-webkit-scrollbar-track {
  background: var(--primary-color);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--gray-color);
  border-radius: 6px;
  border: 3px solid var(--primary-color);
}
